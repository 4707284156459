
.pipeline {
	display:flex;
	flex-direction: column;
	margin-bottom:50px;
}

.header {
	font-weight:700;
	text-align:center;
	border:solid 1px #000;
	border-radius:2px;
	margin:5px;
	background:rgba(100,100,200,1);
	color:#ffffff;
}

.pipelinecol {
	flex: 1;
}

.total {
	text-align:center;
}

.bottombtn {
	background:rgba(255,255,255,0.2);
	position:fixed;
	bottom:0;
	left:0;
	right:0;
	padding:5px;

}

.block {
	margin:5px;
	padding:5px;
	border-radius:3px;
	border:solid 1px #000;
}

