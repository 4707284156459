.title {
	font-weight:700;
	margin-bottom:20px;
	text-align:center;
}

.label {
	margin-top:10px;
}

.btn {
	margin-top:20px;
	text-align:right;
}
